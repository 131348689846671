import { gql } from 'graphql-request';

const branchesQuery = gql`
  query branches($subdomain: String!, $id: ID, $pickupEnabled: Boolean, $dineIn: Boolean, $beachOrderEnabled: Boolean) {
    store(subdomain: $subdomain) {
      id
      branches(id: $id, pickupEnabled: $pickupEnabled, dineIn: $dineIn, beachOrderEnabled: $beachOrderEnabled) {
        id
        titleAr
        titleEn
        addressAr
        addressEn
        areaEn
        busyUntil
        busyFrom
        contactNumber
        phoneNumber
        delivery
        deliveryOrdersEnabled
        beachOrderEnabled
        gmapsUrl
        lat
        lng
        openingHours
        pickupEnabled
        pickupOrdersType
        published
        busyMode
      }
    }
  }
`;

export default branchesQuery;
